import GATSBY_COMPILED_MDX from "/opt/build/repo/content/products/klej-do-bloczkow-z-keramzytobetonu-new.mdx";
import React, {useMemo} from 'react';
import {graphql} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import {MDXProvider} from "@mdx-js/react";
import Seo from '../components/seo';
import AnimationWrapper from '../components/animationWrapper';
import ContactSection from "../components/contactSection";
import Container from '../components/container';
import DownloadSection from '../components/downloadSection';
import Header from '../components/header';
import IconSection from '../components/iconSection';
import Layout from '../components/layout';
import "./productPage.css";
const ProductPage = ({data, location, children}) => {
  const categorySlug = data.product.frontmatter.category;
  const categoryName = categorySlug.replace('-', ' ').replace('sci', 'ści').replace('pelnia', 'pełnia').replace('jace', 'jące');
  const categoryNameCapitalised = categoryName.charAt(0).toUpperCase() + categoryName.slice(1);
  const categoryBreadcrumb = useMemo(() => {
    return {
      title: `${categoryNameCapitalised}`,
      url: `/produkty/${categorySlug}`
    };
  }, [categoryNameCapitalised, categorySlug]);
  useMemo(() => {
    const header = data.page.frontmatter.header;
    header.title = data.product.frontmatter.name;
    header.image = data.header;
    header.breadcrumbs.push(categoryBreadcrumb);
  }, [data, categoryBreadcrumb]);
  const title = data.product.frontmatter.title || `${data.product.frontmatter.name} | ${categoryNameCapitalised} | ${data.page.frontmatter.title}`;
  const description = data.product.frontmatter.meta_description || `Czamaninek ➤ ${data.product.frontmatter.name} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!"`;
  const components = {};
  return React.createElement(Layout, {
    location: location,
    path: categorySlug
  }, React.createElement(Seo, {
    title: title,
    description: description
  }), React.createElement(AnimationWrapper, null, React.createElement(Header, {
    data: data.page.frontmatter.header,
    slug: categorySlug
  })), React.createElement("section", {
    id: "produkt-card",
    className: "page-section"
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, {
    className: `product-card product-card-${data.product.frontmatter.slug}`
  }, React.createElement("div", {
    className: "product-info-wrapper"
  }, React.createElement("h2", {
    className: "product-name"
  }, data.product.frontmatter.name), React.createElement("div", {
    className: "product-subtitle section-subtitle"
  }, data.product.frontmatter.subtitle), data.product.frontmatter.lambda && React.createElement("h3", {
    className: "product-lambda"
  }, " ", React.createElement("span", {
    className: "lambda-sign"
  }, "λ"), " = ", data.product.frontmatter.lambda, " W/mK"), React.createElement("div", {
    className: "product-description"
  }, data.product.frontmatter.desc_short ? data.product.frontmatter.desc_short : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.")), data.product.frontmatter.image && React.createElement("div", {
    className: "product-image-wrapper product-image-main"
  }, React.createElement(GatsbyImage, {
    image: data.product.frontmatter.image.childImageSharp.gatsbyImageData,
    alt: data.product.frontmatter.name
  }))))), React.createElement("section", {
    className: `product-features ${categorySlug}-features page-section`
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, {
    animationType: "fadeIn"
  }, React.createElement(IconSection, {
    data: data.product.frontmatter,
    location: "homepage-keramzyt"
  })))), React.createElement("section", {
    id: "produkt-description",
    className: "page-section"
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, {
    animationType: "fadeIn"
  }, React.createElement("div", {
    className: "section-description product-description"
  }, React.createElement(MDXProvider, {
    components: components
  }, children))))), data.download.nodes[0] && React.createElement(DownloadSection, {
    data: data.download,
    location: "Do pobrania"
  }), React.createElement("section", {
    id: "contact-data",
    className: "page-section"
  }, React.createElement(ContactSection, {
    data: data.contactSection.frontmatter.contact_section
  })));
};
export const query = graphql`
  query(
    $slug: String,
    # $featuresRegex: String,
    $headerPath: String,
    # $category: String,
  ) {
    page: mdx(frontmatter: { slug: { eq: "product-page" }, type: { eq: "page" } }) {
      frontmatter{
        title
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    product: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        name
        title
        slug
        meta_description
        category
        subtitle
        lambda
        desc_short
        image {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {fit:COVER}
              webpOptions: {quality:95}
            )
          }
        }
        image_second {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {fit:COVER}
              webpOptions: {quality:95}
            )
          }
        }
        icons{
          title
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
          text
        }
      }
      internal {
        contentFilePath
      }
    }
    header: file(relativePath: {eq: $headerPath}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    download: allMdx(
      sort: {fields: [frontmatter___order], order: ASC}
      filter: {
        frontmatter: {
          connections: {eq: $slug },
          type: {eq: "download"}
        }
      }
    ) {
      nodes {
        frontmatter {
          name
          file {
            publicURL
          }
        }
        id
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`;
ProductPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ProductPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
